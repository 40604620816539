<template>
  <div class="wrapper">
    <img src="@/assets/bg.png" alt="" class="bg" />
    <div
      ref="nav"
      class="nav animated fadeInDown"
      :style="'position:' + position + ';'"
    >
      <img src="@/assets/logo.png" alt="" class="logo" @click="getScroll" />
      <div style="z-index: 9999">
        <span @click="a1" @tap="a1">网站首页</span>
        <span @click="a2">关于我们</span>
        <span @click="a3">作品欣赏</span>
        <span @click="a4">联系我们</span>
      </div>
    </div>
    <div style="width: 100%; height: 92px"></div>
    <img
      src="@/assets/top.png"
      alt=""
      class="block animated fadeInLeft"
      ref="top"
    />
    <div class="workWrapper">
      <div class="float" @click="openVideo"></div>
      <img src="@/assets/center.png" alt="" class="block work" ref="center" />
    </div>
    <div class="work block" ref="work">
      <img src="@/assets/workText.png" alt="" class="txt" />
      <div class="imgWrapper">
        <div class="img">
          <span>
            <div class="mask"></div>
            <img src="@/assets/1.jpg" alt="" />
          </span>
          <div>
            <div>lnteractive Presentation Box</div>
            <div>交互礼盒</div>
          </div>
        </div>
        <div class="img2">
          <span>
            <div class="mask"></div>
            <img src="@/assets/2.jpg" alt="" />
          </span>

          <div>
            <div>lnteractive Traditional Chinese Painting</div>
            <div>交互国画</div>
          </div>
        </div>
        <div class="img3">
          <span>
            <div class="mask"></div>
            <img src="@/assets/3.jpg" alt="" />
          </span>
          <div>
            <div>lnteractive Desk Calendar</div>
            <div>交互台历</div>
          </div>
        </div>
        <div class="img4">
          <span>
            <div class="mask"></div>
            <img src="@/assets/4.jpg" alt="" />
          </span>
          <div>
            <div>lnteractive Business Card</div>
            <div>交互名片</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" ref="bottom">
      <img src="@/assets/bottom.png" alt="" class="bg" />
      <img src="@/assets/scan.png" alt="" class="scan" />
      <div class="scanText">扫码联系</div>
      <div class="form">
        <div>
          <span>姓名：</span>
          <input type="text" />
        </div>
        <div>
          <span>电话：</span>
          <input type="text" />
        </div>
        <div>
          <span>留言：</span>
          <textarea name="" id="" cols="20" rows="10"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { animate } from "@/assets/isViewPort";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      position: "absolute",
      firstLock: false,
    };
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll, true);
    setTimeout(() => {
      this.$refs.nav.classList.remove("animated", "fadeInDown");
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$refs.nav.classList.remove("animated", "fadeInDown");
  },
  methods: {
    openVideo(){
      window.open('https://www.zrzw.tingxi8087.com/video.mp4')
    },
    handleScroll() {
      animate(this.$refs.top, "fadeInLeft");
      animate(this.$refs.center, "fadeInRight");
      animate(this.$refs.work, "fadeInLeft");
      animate(this.$refs.bottom, "fadeInRight");
      if (!this.firstLock) {
        this.firstLock = true;
        return;
      }
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 92) {
        this.position = "fixed";
        this.$refs.nav.classList.add("animated", "fadeInDown", "touming");
      }
      if (scrollTop == 0) {
        this.$refs.nav.classList.remove("animated", "fadeInDown", "touming");
        this.position = "absolute";
      }
    },
    getScroll() {
      console.log(document.documentElement.scrollTop);
    },
    a2() {
      if (document.body.offsetWidth <= 500) {
        window.scrollTo(0, 295);
      } else {
        window.scrollTo(0, 800);
      }
    },
    a1() {
      if (document.body.offsetWidth <= 500) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    },
    a3() {
      if (document.body.offsetWidth <= 500) {
        window.scrollTo(0, 525);
      } else {
        window.scrollTo(0, 1638);
      }
    },
    a4() {
      window.scrollTo(0, 2780);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 500px) {
  .imgWrapper {
    font-size: 8px !important;
    span {
      margin: 10px 0 !important;
    }
  }
  .nav {
    padding: 10px 10px !important;
    > div {
      font-size: 10px !important;
      width: 60% !important;
    }
    .logo {
      width: 20%;
    }
  }
  .workWrapper{
    .float{
      height: calc(100% - 50px) !important;
    }
  }
  .block {
    margin-bottom: 50px !important;
  }
  .bottom {
    margin-bottom: 100px !important;
    .scan {
      width: 20%;
    }
    .scanText {
      cursor: default;
      position: absolute;
      top: calc(103%) !important;
      color: white;
      left: calc(40%) !important;
    }
    .form {
      font-size: 8px;
      input {
        width: 60% !important;
      }
      div {
        margin-bottom: 5px !important;
      }
      textarea {
        width: 60%;
        height: 80px;
      }
    }
  }
}
.wrapper {
  max-width: 2500px;
  // background-color: #650002;
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
.touming {
  background-color: rgba(107, 4, 7, 0.9) !important;
}
.work {
  width: 100%;
  position: relative;
  .txt {
    position: absolute;
    right: 20%;
    width: 20%;
  }
  .imgWrapper {
    width: 100%;
    display: flex;
    font-size: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    color: white;
    > div {
      span {
        display: block;
        position: relative;
        margin: 20px 0;
        transition: all 1s;
        .mask {
          position: absolute;
          width: 100%;
          height: 99%;
          background-color: rgba(239, 166, 168, 0.6);
          transition: all 1s;
        }
        &:hover {
          transform: scale(1.05);
          .mask {
            background-color: transparent;
          }
        }
      }

      > div {
        margin-bottom: 10px;
      }
    }
    .img {
      width: 52%;
      padding-top: 11.5%;
    }
    .img2 {
      width: 46%;
      box-sizing: border-box;
      padding-top: 14.5%;
    }
    .img3 {
      width: 55%;
    }
    .img4 {
      width: 43%;
      padding-top: 5.82%;
    }
    img {
      width: 100%;
    }
  }
}
.block {
  width: 100%;
  // margin: 0 auto;
  margin-bottom: 180px;
  opacity: 0;
}
.workWrapper{
  position: relative;
  .float{
    top: 0;
    left: 15%;
    height: calc(100% - 180px);
    width: 30%;
    position: absolute;
    z-index: 9999;
  }
}
.wrapper > .bg {
  width: 100%;
  position: absolute;
  min-height: 2000px;
  z-index: -1;
}
.bottom {
  width: 100%;
  opacity: 0;
  position: relative;
  .bg {
    width: 100%;
  }
  .scan {
    left: 18.5%;
    position: absolute;
    top: 70%;
  }
  .scanText {
    cursor: default;
    position: absolute;
    top: calc(70% + 125px);
    color: white;
    left: calc(18% + 160px);
  }
  .form {
    left: 54%;
    position: absolute;
    top: 36%;
    div {
      margin-bottom: 30px;
      display: flex;
    }
    textarea {
      outline: none;
      resize: none;
    }
    span {
      color: white;
    }
    input {
      width: 234px;
      outline: none;
    }
  }
}
.nav {
  box-sizing: border-box;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  z-index: 9999;
  align-items: center;
  background-color: rgba(107, 4, 7, 0.9);
  div {
    width: 400px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
  }
}
</style>
